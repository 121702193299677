define("discourse/plugins/discourse-user-notes/discourse-user-notes/lib/user-notes", ["exports", "discourse/lib/show-modal"], function (_exports, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showUserNotes = showUserNotes;
  function showUserNotes(store, userId, callback, opts) {
    opts = opts || {};
    return store.find("user-note", {
      user_id: userId
    }).then(model => {
      const controller = (0, _showModal.default)("user-notes", {
        model,
        title: "user_notes.title",
        addModalBodyView: true
      });
      controller.reset();
      controller.setProperties({
        userId,
        callback,
        postId: opts.postId
      });
      return controller;
    });
  }
});